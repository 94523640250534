.page-product .editor_content ol {
  list-style-type: decimal;
  padding-left: 10px;
}
.page-product .editor_content ul {
  list-style-type: initial;
  padding-left: 20px;
}
.page-product .editor_content img {
  max-width: 100%;
  height: auto !important;
}
.page-product .editor_content b,
.page-product .editor_content strong {
  font-weight: bold;
  font-size: inherit;
}
.page-product .editor_content em {
  font-style: italic;
}
.page-product .editor_content a {
  text-decoration: underline;
}
.page-product .editor_content iframe {
  max-width: 100%;
}
.page-product .editor_content h1,
.page-product .editor_content h2,
.page-product .editor_content h3,
.page-product .editor_content h4,
.page-product .editor_content h5,
.page-product .editor_content h6 {
  margin: inherit;
}
.page-product .editor_content blockquote {
  font-style: italic;
  padding: 2px 20px 0 8px;
  margin-left: 40px;
  border-left: 5px solid #ccc;
}
.page-product .editor_content td,
.page-product .editor_content th,
.page-product .editor_content tr {
  margin: initial;
}
.page-product .editor_box {
  margin: auto;
  line-height: 2.25;
  font-size: 1rem;
  color: #404040;
}
.page-product .editor_box ul,
.page-product .editor_box ol {
  padding-left: 40px;
}
.page-product .editor_box img {
  max-width: 100%;
  height: auto !important;
}
.page-product .editor_box iframe {
  max-width: 100%;
}
@media screen and (max-width: 767px) {
  .page-product .editor_box iframe {
    height: 100% !important;
  }
}
.page-product .editor_box h1,
.page-product .editor_box h2,
.page-product .editor_box h3,
.page-product .editor_box h4,
.page-product .editor_box h5,
.page-product .editor_box h6 {
  margin: inherit;
}
.page-product .mo_use {
  display: none;
}
@media (max-width: 991px) {
  .page-product .editor_box {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .page-product .editor_box {
    font-size: 1rem;
    line-height: 2;
  }
  .page-product .mo_use {
    display: block;
  }
  .page-product .pc_use {
    display: none;
  }
}
.page-product .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (min-width: 768px) {
  .page-product .form-group {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
}
@media (max-width: 767px) {
  .page-product .form-group {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.page-product .form-label {
  color: #2da9ef;
  font-weight: 700;
  min-width: 135px;
  padding-right: 20px;
}
@media (max-width: 1440px) {
  .page-product .form-label {
    font-size: 1.5rem;
  }
}
@media (max-width: 767px) {
  .page-product .form-label {
    font-size: 1.125rem;
  }
}
@media (min-width: 1441px) {
  .page-product .form-label {
    font-size: 1.5rem;
  }
}
@media (max-width: 767px) {
  .page-product .form-label {
    margin-bottom: 12px;
  }
}
@media (max-width: 1440px) {
  .page-product .form-label i {
    font-size: 1.875rem;
  }
}
@media (max-width: 767px) {
  .page-product .form-label i {
    font-size: 1.25rem;
  }
}
@media (min-width: 1441px) {
  .page-product .form-label i {
    font-size: 1.875rem;
  }
}
.page-product .form-input {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.page-product .form-input small {
  font-size: 0.875rem;
  color: #2da9ef;
  line-height: 1;
  display: block;
  margin-top: 13px;
  opacity: 0;
}
@media (max-width: 767px) {
  .page-product .form-input small {
    font-size: 0.75rem;
    margin-top: 5px;
  }
}
.page-product .req .form-input input {
  border: 1px solid #2da9ef;
}
.page-product .req .form-input small {
  opacity: 1;
}
.page-product input[type=text],
.page-product input[type=email] {
  width: 100%;
  font-size: 1rem;
  border-radius: 20px;
  border: none;
  padding: 20px 24px;
  background-color: #f6f6f6;
  font-family: "Lexend", "Noto Sans TC", sans-serif;
}
.page-product input[type=text]::-webkit-input-placeholder, .page-product input[type=email]::-webkit-input-placeholder {
  color: #999999;
}
.page-product input[type=text]::-moz-placeholder, .page-product input[type=email]::-moz-placeholder {
  color: #999999;
}
.page-product input[type=text]:-ms-input-placeholder, .page-product input[type=email]:-ms-input-placeholder {
  color: #999999;
}
.page-product input[type=text]::-ms-input-placeholder, .page-product input[type=email]::-ms-input-placeholder {
  color: #999999;
}
.page-product input[type=text]::placeholder,
.page-product input[type=email]::placeholder {
  color: #999999;
}
@media (max-width: 767px) {
  .page-product input[type=text],
.page-product input[type=email] {
    padding: 14px 18px;
  }
}
.page-product input:not(:checked) ~ label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.page-product input:checked ~ label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.page-product input[type=radio] {
  display: none;
}
.page-product input[type=radio] ~ label {
  position: relative;
  padding-left: 40px;
  cursor: pointer;
}
.page-product input[type=radio] ~ label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background-color: #eeeeee;
}
.page-product input[type=radio] ~ label::after {
  content: "";
  background: url(/public/dist/images/check.png) center/16px no-repeat;
  position: absolute;
  width: 16px;
  height: 12px;
  left: 5px;
  top: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
@media (max-width: 767px) {
  .page-product input[type=radio] ~ label {
    padding-left: 35px;
  }
  .page-product input[type=radio] ~ label::before {
    width: 24px;
    height: 24px;
  }
  .page-product input[type=radio] ~ label::after {
    width: 16px;
    height: 12px;
    left: 3.5px;
  }
}
.page-product textarea {
  width: 100%;
  font-size: 1rem;
  border-radius: 20px;
  border: none;
  padding: 20px 24px;
  background-color: #f6f6f6;
  font-family: "Lexend", "Noto Sans TC", sans-serif;
  resize: none;
}
.page-product textarea::-webkit-input-placeholder {
  color: #999999;
}
.page-product textarea::-moz-placeholder {
  color: #999999;
}
.page-product textarea:-ms-input-placeholder {
  color: #999999;
}
.page-product textarea::-ms-input-placeholder {
  color: #999999;
}
.page-product textarea::placeholder {
  color: #999999;
}
@media (max-width: 767px) {
  .page-product textarea {
    padding: 14px 18px;
  }
}

.product-section {
  position: relative;
}
@media (min-width: 768px) {
  .product-section {
    padding-top: 200px;
    margin-bottom: 185px;
  }
}
@media (max-width: 767px) {
  .product-section {
    padding-top: 110px;
    margin-bottom: 85px;
  }
}
.product-section::after {
  content: "";
  width: 100%;
  aspect-ratio: 1/0.46875;
  background: url(/public/dist/images/dot.jpg) center/contain no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -99;
}
@media (min-width: 768px) {
  .product-section .f44-30 {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .product-section .f44-30 {
    margin-bottom: 35px;
  }
}
.product-section .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (min-width: 768px) {
  .product-section .info {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .product-section .info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.product-section .info .product-img {
  position: relative;
}
@media (min-width: 768px) {
  .product-section .info .product-img {
    width: 34%;
    margin-right: 8%;
  }
}
@media (max-width: 767px) {
  .product-section .info .product-img {
    width: 100%;
  }
}
.product-section .info .product-img .swiper {
  width: 100%;
  height: 100%;
  overflow: initial;
}
.product-section .info .product-img .swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  aspect-ratio: 1/1;
}
.product-section .info .product-img .swiper-slide img {
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  -o-object-fit: contain;
     object-fit: contain;
}
.product-section .info .product-img .img-wrap {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 30px;
  -webkit-box-shadow: 0px 5px 16px 4px rgba(221, 221, 221, 0.7);
          box-shadow: 0px 5px 16px 4px rgba(221, 221, 221, 0.7);
  background-color: #fff;
  padding: 20%;
}
.product-section .info .product-img .swiper-pagination {
  width: 100%;
  position: relative;
  margin-top: 43px;
}
.product-section .info .product-img .swiper-pagination .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  margin: 5px 15px;
}
.product-section .info .product-img .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #404040;
}
@media (min-width: 768px) {
  .product-section .info .product-detail {
    width: 51%;
    margin-top: 5%;
  }
}
@media (max-width: 767px) {
  .product-section .info .product-detail {
    width: 100%;
    margin-top: 15px;
  }
}
@media (min-width: 768px) {
  .product-section .info .product-detail .product-name {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .product-section .info .product-detail .product-name {
    margin-bottom: 8px;
  }
}
.product-section .info .product-detail .divider {
  width: 100%;
  height: 1px;
  background-color: #cccccc;
}
@media (min-width: 768px) {
  .product-section .info .product-detail .divider {
    margin: 33px auto 42px;
  }
}
@media (max-width: 767px) {
  .product-section .info .product-detail .divider {
    margin: 18px auto 30px;
  }
}
@media (min-width: 768px) {
  .product-section .info .product-detail p {
    line-height: 1.875;
  }
}
@media (max-width: 767px) {
  .product-section .info .product-detail p {
    line-height: 1.5265;
  }
}

.description-section {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .send-section {
    margin-bottom: 165px;
  }
}
@media (max-width: 767px) {
  .send-section {
    margin-bottom: 90px;
  }
}
.send-section .f30-20 {
  color: #2da9ef;
}
@media (min-width: 768px) {
  .send-section .f30-20 {
    margin-bottom: 35px;
  }
}
@media (max-width: 767px) {
  .send-section .f30-20 {
    margin-bottom: 15px;
  }
}
.send-section .white-bg {
  padding: 40px 90px 30px 100px;
  border-radius: 30px;
  -webkit-box-shadow: 0px 5px 16px 4px rgba(221, 221, 221, 0.7);
          box-shadow: 0px 5px 16px 4px rgba(221, 221, 221, 0.7);
  background-color: #fff;
  margin-bottom: 60px;
}
@media (max-width: 991px) {
  .send-section .white-bg {
    padding: 20px 45px 15px 50px;
  }
}
@media (max-width: 767px) {
  .send-section .white-bg {
    padding: 30px 25px;
    border-radius: 15px;
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) {
  .send-section .white-bg .f16 {
    margin-bottom: 18px;
  }
}
@media (max-width: 767px) {
  .send-section .white-bg .f16 {
    margin-bottom: 15px;
  }
}
.send-section .flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 991px) {
  .send-section .flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.send-section .flex .form-group {
  width: 47%;
}
@media (max-width: 991px) {
  .send-section .flex .form-group {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .send-section .form-group {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media (min-width: 768px) {
  .send-section .form-group {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .send-section .form-group {
    margin-bottom: 15px;
  }
}
.send-section .form-group.req .form-label {
  font-weight: 400;
}
.send-section .form-label {
  font-size: 1rem;
  color: #404040;
}
@media (min-width: 992px) {
  .send-section .form-label {
    min-width: 100px;
  }
}
@media (max-width: 991px) {
  .send-section .form-label {
    margin-bottom: 10px;
  }
}
.send-section .form-label i {
  font-size: 1rem;
  color: #2da9ef;
}
@media (max-width: 991px) {
  .send-section .form-input {
    width: 100%;
  }
}
.send-section .chekbox-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 768px) {
  .send-section .chekbox-wrap .checkbox-group {
    width: 130px;
  }
}
@media (max-width: 767px) {
  .send-section .chekbox-wrap .checkbox-group {
    margin-right: 20px;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .send-section textarea {
    height: 120px;
  }
}
@media (max-width: 767px) {
  .send-section textarea {
    height: 140px;
  }
}
.send-section .btnwrap .btn {
  background-color: #f7bf0a;
}
@media (min-width: 768px) {
  .send-section .btnwrap .btn:hover {
    background-color: #1b9cff;
  }
}
.send-section .btnwrap span {
  width: 12px;
}
@media (max-width: 767px) {
  .send-section .btnwrap span {
    background-size: 11px;
  }
}
.send-section .btnwrap span.money {
  background: url(/public/dist/images/icon_money.png) center/12px no-repeat;
}
@media (max-width: 767px) {
  .send-section .btnwrap span.money {
    background-size: 11px;
  }
}