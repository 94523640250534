@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap');

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
time,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font: inherit
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

ol,
ul {
    list-style: none
}

*,
:after,
:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.navbar {
    position: fixed;
    z-index: 99;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease
}

@media screen and (min-width:1025px) {
    .navbar {
        background-color: white;
        width: calc(100% - 5.2vw);
        height: 90px;
        padding: 0 2.6vw 0 2vw;
        -webkit-box-shadow: 0 0 18.4px 1.6px rgba(221, 221, 221, 0.5);
        box-shadow: 0 0 18.4px 1.6px rgba(221, 221, 221, 0.5);
        border-radius: 45px;
        top: 30px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }
}

@media screen and (max-width:1024px) {
    .navbar {
        width: 100%;
        height: 45px;
        top: 0;
        left: 0;
        background-color: transparent
    }

    .navbar:after {
        content: "";
        width: 95vw;
        height: 45px;
        background-color: #fff;
        -webkit-box-shadow: 0 0 9.2px 0.8px rgba(221, 221, 221, 0.5);
        box-shadow: 0 0 9.2px 0.8px rgba(221, 221, 221, 0.5);
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        top: 15px;
        border-radius: 45px;
        z-index: 1
    }

    .navbar.open .container__menu {
        opacity: 1;
        visibility: visible;
        pointer-events: initial
    }

    .navbar.open .container__member {
        display: block;
        top: 50px;
    }

    .navbar.open .container__burger span {
        background: rgba(255, 255, 255, 0);
        width: 20px;
        height: 2px
    }

    .navbar.open .container__burger span:after {
        width: 20px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        bottom: 0px;
        background: #404040;
        height: 2px
    }

    .navbar.open .container__burger span:before {
        width: 20px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        top: 0px;
        background: #404040;
        height: 2px
    }
}

.navbar .container__logo {
    z-index: 2;
    /* margin-top: -35px;
    margin-left: 0vw */
}

.navbar .container__logo a {
    display: inline-block;
    width: 188px;
    height: 30px;
    position: relative;
}

@media screen and (max-width:1024px) {
    .navbar .container__logo a {
        width: 113px;
        height: 18px
    }
}

.navbar .container__logo embed {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    pointer-events: none
}

@media screen and (max-width:1024px) {
    .navbar .container__logo {
        margin-top: 33px;
        margin-left: 6.6vw;
        /* margin-top: 10px;
        margin-left: 5vw */
    }
}

@media screen and (min-width:1025px) {
    .navbar .container__menu {
        -webkit-box-flex: 2;
        -ms-flex-positive: 2;
        flex-grow: 2;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        height: 100%
    }
}

@media screen and (max-width:1024px) {
    .navbar .container__menu {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        overflow-y: auto;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
        background-color: #fff;
        padding-top: 11vh
    }
}

.navbar .container__menu .menu__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media screen and (max-width:1024px) {
    .navbar .container__menu .menu__wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

.navbar .container__menu .menu__title {
    position: relative;
    height: 100%
}

.navbar .container__menu .menu__title>button {
    color: #404040;
    display: block;
    font-size: 1rem
}

@media screen and (min-width:1025px) {
    .navbar .container__menu .menu__title>button {
        line-height: 90px
    }
}

@media screen and (max-width:1024px) {
    .navbar .container__menu .menu__title>button {
        padding: 20px 10.4vw
    }
}

.navbar .container__menu .menu__title>button:after {
    content: "";
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -15px;
    width: 100%;
    height: 15px
}

@media screen and (min-width:1025px) {
    .navbar .container__menu .menu__title.active>button {
        color: #1b9cff
    }
}

.navbar .container__menu .menu__title.active .menu__sub {
    opacity: 1;
    visibility: visible;
    pointer-events: initial
}

@media (min-width:768px) {
    .navbar .container__menu .menu__title:hover>button {
        color: #1b9cff
    }
}

@media screen and (min-width:1025px) {
    .navbar .container__menu .menu__title {
        margin-right: 2.6vw
    }
}

@media screen and (max-width:1024px) {
    .navbar .container__menu .menu__title {
        width: 100%
    }

    .navbar .container__menu .menu__title.active:after {
        display: none
    }

    .navbar .container__menu .menu__title:after {
        content: "";
        width: 90%;
        height: 1px;
        background-color: #dddddd;
        position: absolute;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    .navbar .container__menu .menu__title.mit {
        background: url(/public/dist/images/arrow_up.png) top 30px right 10%/13px no-repeat
    }

    .navbar .container__menu .menu__title.mit.active {
        background: url(/public/dist/images/arrow_down.png) top 30px right 10%/13px no-repeat
    }
}

.navbar .container__menu .menu__sub {
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media screen and (min-width:1025px) {
    .navbar .container__menu .menu__sub {
        position: absolute;
        max-width: 500px;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        border-radius: 20px;
        padding: 21px 30px;
        top: 105px;
        left: -21px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        z-index: -1;
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
        background-color: #f6f6f6;
        -webkit-box-shadow: 0 0 18.4px 1.6px rgba(221, 221, 221, 0.5);
        box-shadow: 0 0 18.4px 1.6px rgba(221, 221, 221, 0.5)
    }
}

@media screen and (max-width:1024px) {
    .navbar .container__menu .menu__sub {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        background-color: #f6f6f6;
        padding: 20px 10.4vw;
        display: none
    }
}

@media screen and (min-width:1025px) {
    .navbar .container__menu .menu__sub li:not(:last-child) {
        margin-bottom: 10px
    }
}

@media screen and (max-width:1024px) {
    .navbar .container__menu .menu__sub li:not(:last-child) {
        margin-bottom: 12px
    }
}

.navbar .container__menu .menu__sub li a {
    display: block;
    max-width: 180px;
    color: #5d5b5b
}

.navbar .container__menu .menu__sub li.active a {
    color: #1b9cff
}

@media (min-width:768px) {
    .navbar .container__menu .menu__sub li:hover a {
        color: #1b9cff
    }
}

@media screen and (min-width:1025px) {
    .navbar .container__menu .menu__sub.member-sub .menu__sub__wrap:first-child {
        margin-bottom: 0px
    }
}

@media screen and (max-width:1024px) {
    .navbar .container__menu .menu__sub.member-sub .menu__sub__wrap:first-child {
        margin-bottom: 20px
    }
}

.navbar .container__menu .menu__sub.member-sub .line {
    width: 1px;
    height: auto;
    background-color: #dddddd
}

@media screen and (min-width:1025px) {
    .navbar .container__menu .menu__sub.member-sub .line {
        margin: 0 30px
    }
}

@media screen and (max-width:1024px) {
    .navbar .container__menu .menu__sub.member-sub .line {
        display: none
    }
}

.navbar .container__menu .menu__sub.member-sub .wrap-title {
    font-weight: 700;
    margin-bottom: 22px
}

@media (max-width:1440px) {
    .navbar .container__menu .menu__sub.member-sub .wrap-title {
        font-size: 1.125rem
    }
}

@media (max-width:767px) {
    .navbar .container__menu .menu__sub.member-sub .wrap-title {
        font-size: 1.0625rem
    }
}

@media (min-width:1441px) {
    .navbar .container__menu .menu__sub.member-sub .wrap-title {
        font-size: 1.125rem
    }
}

@media screen and (max-width:1024px) {
    .navbar .container__menu .menu__sub.member-sub .wrap-title {
        margin-bottom: 15px
    }
}

@media screen and (max-width:1024px) {
    .navbar .container__member {
        display: none;
        position: fixed;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        padding: 20px 15px;
        background-color: #fff;
        -webkit-box-shadow: 0 0 18.4px 1.6px rgba(221, 221, 221, 0.5);
        box-shadow: 0 0 18.4px 1.6px rgba(221, 221, 221, 0.5);
        width: 100%
    }
}

.navbar .container__member ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media screen and (max-width:1024px) {
    .navbar .container__member ul {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.navbar .container__member ul li:first-child {
    margin-right: 0.9vw
}

@media screen and (max-width:1024px) {
    .navbar .container__member ul li:first-child {
        margin-right: 10px
    }
}

.navbar .container__member ul .logoutbtn,
.navbar .container__member ul .signinbtn {
    background-color: #404040
}

@media (min-width:768px) {

    .navbar .container__member ul .logoutbtn:hover,
    .navbar .container__member ul .signinbtn:hover {
        background-color: #1b9cff
    }
}

.navbar .container__member ul .loginbtn {
    background-color: #ef9500
}

@media (min-width:768px) {
    .navbar .container__member ul .loginbtn:hover {
        background-color: #ef8500
    }
}

.navbar .container__member ul a {
    display: block;
    width: 140px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-weight: 700;
    color: white;
    border-radius: 24px
}

@media (max-width:1200px) {
    .navbar .container__member ul a {
        width: 100px
    }
}

@media screen and (max-width:1024px) {
    .navbar .container__member ul a {
        width: 165px
    }
}

@media (min-width:768px) {
    .navbar .container__member:hover .circle {
        background-color: black;
        -webkit-box-shadow: 0 0 0 1px #ffb500;
        box-shadow: 0 0 0 1px #ffb500
    }

    .navbar .container__member:hover .f16 {
        color: #ffb500
    }
}

@media screen and (min-width:1025px) {
    .navbar .container__sign {
        display: none
    }
}

@media screen and (max-width:1024px) {
    .navbar .container__sign {
        z-index: 10;
        -webkit-box-flex: 2;
        -ms-flex-positive: 2;
        flex-grow: 2
    }

    .navbar .container__sign a {
        display: block;
        width: 70px;
        height: 30px;
        line-height: 30px;
        background-color: #404040;
        text-align: center;
        color: white;
        border-radius: 30px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: 14px;
        font-size: 0.875rem
    }
}

@media screen and (max-width:1024px) {
    .navbar .container__burger .burger {
        position: relative;
        width: 40px;
        height: 40px;
        cursor: pointer;
        margin-top: 30px;
        margin-right: 6.6vw;
        z-index: 10
    }

    .navbar .container__burger span {
        position: absolute;
        background: #404040;
        width: 20px;
        height: 2px;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        opacity: 1;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out
    }

    .navbar .container__burger span:before {
        position: absolute;
        background: #404040;
        width: 20px;
        height: 2px;
        top: 6px;
        right: 0;
        content: "";
        display: block;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        border-radius: 2px
    }

    .navbar .container__burger span:after {
        position: absolute;
        background: #404040;
        width: 20px;
        height: 2px;
        bottom: 6px;
        right: 0;
        content: "";
        display: block;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        border-radius: 2px
    }
}

footer {
    position: relative;
    background-color: #e7f5fd;
    padding: 100px 0 80px;
    border-radius: 250px 0 0 0
}

@media (max-width:991px) {
    footer {
        padding: 50px 0 40px;
        border-radius: 70px 0 0 0
    }
}

@media (max-width:767px) {
    footer {
        padding: 70px 0 32px
    }
}

.footer__wrap {
    padding-left: 80px
}

@media (max-width:1200px) {
    .footer__wrap {
        padding-left: 40px
    }
}

@media (max-width:991px) {
    .footer__wrap {
        padding-left: 20px
    }
}

@media (max-width:768px) {
    .footer__wrap {
        padding-left: 0
    }
}

.footer__logo {
    margin-bottom: 46px
}

@media (max-width:767px) {
    .footer__logo {
        margin-bottom: 33px;
        padding-left: 10px
    }
}

.footer__logo a {
    display: block;
    width: 302px
}

@media (max-width:767px) {
    .footer__logo a {
        width: 222px
    }
}

.footer__logo embed {
    width: 100%
}

.footer__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media (max-width:767px) {
    .footer__info {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

@media (max-width:767px) {
    .footer__info ul {
        margin-bottom: 28px;
        padding-left: 10px
    }
}

.footer__info li {
    margin-bottom: 7px
}

@media (max-width:767px) {
    .footer__info li {
        margin-bottom: 5px
    }
}

.footer__info span {
    display: inline-block;
    font-weight: 700;
    width: 70px
}

.footer__info .official-btn a {
    display: block;
    background-color: #404040;
    color: white;
    width: 140px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border-radius: 50px
}

@media (min-width:768px) {
    .footer__info .official-btn a:hover {
        background-color: #1b9cff
    }
}

.footer__line {
    width: 100%;
    height: 1px;
    background-color: #dae6ec;
    margin: 30px 0 15px
}

@media (max-width:767px) {
    .footer__line {
        margin: 40px 0 20px
    }
}

.footer__copyright {
    color: #999999;
    line-height: 1.5
}

@media (max-width:1440px) {
    .footer__copyright {
        font-size: 0.875rem
    }
}

@media (max-width:767px) {
    .footer__copyright {
        font-size: 0.75rem
    }
}

@media (min-width:1441px) {
    .footer__copyright {
        font-size: 0.875rem
    }
}

.top_btn {
    position: fixed;
    right: 35px;
    bottom: 90px;
    cursor: pointer;
    width: 78px;
    height: 78px;
    background: url(/public/dist/images/top.png) center/contain no-repeat;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    opacity: 0;
    z-index: 100
}

@media (max-width:767px) {
    .top_btn {
        background: url(/public/dist/images/top_mb.png) center/contain no-repeat;
        width: 60px;
        height: 60px;
        right: 20px;
        bottom: 60px
    }
}

.top_btn.show {
    opacity: 1
}

@media (max-width:767px) {
    .top_btn.nonfixed {
        bottom: 120px
    }
}

@media (min-width:768px) {
    .top_btn:hover {
        background: url(/public/dist/images/top_hover.png) center/contain no-repeat
    }
}

::-moz-selection {
    background-color: #1b9cff;
    color: white
}

::selection {
    background-color: #1b9cff;
    color: white
}

body,
html {
    overflow-x: hidden
}

#body-content {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.6s ease-in;
    transition: 0.6s ease-in
}

#body-content.loading {
    opacity: 1;
    visibility: visible
}

body {
    font-family: "Lexend", "Noto Sans TC", sans-serif;
    color: #404040;
    font-weight: 400;
    position: relative
}

.mo {
    display: none
}

@media (max-width:767px) {
    .mo {
        display: block
    }
}

.pc {
    display: none
}

@media (min-width:768px) {
    .pc {
        display: block
    }
}

.container {
    margin: 0 auto;
    width: 100%
}

@media (max-width:767px) {
    .container {
        padding: 0 20px
    }
}

@media (min-width:768px) {
    .container {
        width: 80%
    }
}

@media (min-width:992px) {
    .container {
        width: 90%
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1200px;
        padding: 0 16px
    }
}

a {
    outline: none;
    color: inherit
}

a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important
}

a,
a:hover {
    text-decoration: none;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease
}

:focus {
    outline: none
}

img {
    vertical-align: middle;
    max-width: 100%
}

.lang_tw .tw {
    display: block
}

.lang_tw .en {
    display: none
}

.lang_en .tw {
    display: none
}

.lang_en .en {
    display: block
}

.banner-section {
    position: relative
}

.banner-section .bg .banner {
    width: 100%;
    background-position: center;
    background-size: cover
}

@media (min-width:768px) {
    .banner-section .bg .banner {
        height: 450px
    }
}

@media (max-width:767px) {
    .banner-section .bg .banner {
        aspect-ratio: 1/0.71354
    }
}

.banner-section .container {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

@media (min-width:768px) {
    .banner-section .container {
        top: 51%
    }
}

@media (max-width:767px) {
    .banner-section .container {
        top: 45%
    }
}

.banner-section .container .main-title {
    font-weight: 700
}

@media (max-width:1440px) {
    .banner-section .container .main-title {
        font-size: 2.75rem
    }
}

@media (max-width:767px) {
    .banner-section .container .main-title {
        font-size: 1.875rem
    }
}

@media (min-width:1441px) {
    .banner-section .container .main-title {
        font-size: 2.75rem
    }
}

.banner-section .yel-ball {
    width: 17.552%;
    aspect-ratio: 1/0.967;
    position: absolute;
    right: 0;
    bottom: -54%;
    background: url(/public/dist/images/yel_ball04.png) center/contain no-repeat
}

@media (max-width:1200px) {
    .banner-section .yel-ball {
        display: none
    }
}

.fw7 {
    font-weight: 700
}

.fw5 {
    font-weight: 500
}

@media (max-width:1440px) {
    .f44-30 {
        font-size: 2.75rem
    }
}

@media (max-width:767px) {
    .f44-30 {
        font-size: 1.875rem
    }
}

@media (min-width:1441px) {
    .f44-30 {
        font-size: 2.75rem
    }
}

@media (max-width:1440px) {
    .f36-25 {
        font-size: 2.25rem
    }
}

@media (max-width:767px) {
    .f36-25 {
        font-size: 1.5625rem
    }
}

@media (min-width:1441px) {
    .f36-25 {
        font-size: 2.25rem
    }
}

@media (max-width:1440px) {
    .f36-20 {
        font-size: 2.25rem
    }
}

@media (max-width:767px) {
    .f36-20 {
        font-size: 1.25rem
    }
}

@media (min-width:1441px) {
    .f36-20 {
        font-size: 2.25rem
    }
}

@media (max-width:1440px) {
    .f30-20 {
        font-size: 1.875rem
    }
}

@media (max-width:767px) {
    .f30-20 {
        font-size: 1.25rem
    }
}

@media (min-width:1441px) {
    .f30-20 {
        font-size: 1.875rem
    }
}

.f24 {
    font-size: 1.5rem
}

@media (max-width:1440px) {
    .f24-18 {
        font-size: 1.5rem
    }
}

@media (max-width:767px) {
    .f24-18 {
        font-size: 1.125rem
    }
}

@media (min-width:1441px) {
    .f24-18 {
        font-size: 1.5rem
    }
}

@media (max-width:1440px) {
    .f22-18 {
        font-size: 1.375rem
    }
}

@media (max-width:767px) {
    .f22-18 {
        font-size: 1.125rem
    }
}

@media (min-width:1441px) {
    .f22-18 {
        font-size: 1.375rem
    }
}

.f18 {
    font-size: 1.125rem
}

.btnwrap .btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 240px;
    padding: 23px 0;
    background-color: #404040;
    color: white;
    border-radius: 35px;
    margin: 0 auto
}

@media (max-width:767px) {
    .btnwrap .btn {
        width: 200px;
        padding: 18px 0
    }
}

.btnwrap .btn span {
    display: block;
    width: 14px;
    margin-left: 15px
}

@media (max-width:767px) {
    .btnwrap .btn span {
        margin-left: 5px
    }
}

.btnwrap .btn span.more {
    background: url(/public/dist/images/icon_more.png) center/14px no-repeat
}

@media (min-width:768px) {
    .btnwrap .btn:hover {
        background-color: #1b9cff
    }
}

.btnwrap.small .btn {
    width: 180px;
    padding: 18px 0;
    margin: 0 auto 0 0
}

sup {
    vertical-align: super;
    font-size: 12px
}

.animatable {
    visibility: hidden;
    -webkit-animation-play-state: paused;
    animation-play-state: paused
}

.animated {
    visibility: visible;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-play-state: running;
    animation-play-state: running
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0)
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }

    20% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    60% {
        opacity: 0
    }

    20% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes bounceInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px)
    }

    60% {
        -webkit-transform: translateX(20px)
    }

    80% {
        -webkit-transform: translateX(-5px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0)
    }
}

@keyframes bounceInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px)
    }

    60% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px)
    }

    80% {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes bounceInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px)
    }

    60% {
        -webkit-transform: translateX(-20px)
    }

    80% {
        -webkit-transform: translateX(5px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0)
    }
}

@keyframes bounceInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px)
    }

    60% {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px)
    }

    80% {
        -webkit-transform: translateX(5px);
        transform: translateX(5px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0)
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.3)
    }

    50% {
        -webkit-transform: scale(1.05)
    }

    70% {
        -webkit-transform: scale(0.9)
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1)
    }
}

@keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.3);
        transform: scale(0.3)
    }

    50% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05)
    }

    70% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9)
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes moveUp {
    0% {
        opacity: 1;
        -webkit-transform: translateY(40px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0)
    }
}

@keyframes moveUp {
    0% {
        opacity: 1;
        -webkit-transform: translateY(40px);
        transform: translateY(40px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes fadeBgColor {
    0% {
        background: none
    }

    70% {
        background: none
    }

    to {
        background: #464646
    }
}

@keyframes fadeBgColor {
    0% {
        background: none
    }

    70% {
        background: none
    }

    to {
        background: #464646
    }
}

.animated.animationDelay {
    animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s
}

.animated.animationDelayMed {
    animation-delay: 1.2s;
    -webkit-animation-delay: 1.2s
}

.animated.animationDelayLong {
    animation-delay: 1.6s;
    -webkit-animation-delay: 1.6s
}

.animated.fadeBgColor {
    -webkit-animation-name: fadeBgColor;
    animation-name: fadeBgColor
}

.animated.bounceIn {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn
}

.animated.bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight
}

.animated.bounceInLeft {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft
}

.animated.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn
}

.animated.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown
}

.animated.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}

.animated.moveUp {
    -webkit-animation-name: moveUp;
    animation-name: moveUp
}
